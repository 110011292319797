<template>
  <div class="page bg-white" id="reorganize">
    <div style="height: calc(100vh - 215px);overflow-y: auto">
      <div class="minTitle">捐赠者/提供信息者基本信息</div>
      <el-row>
        <el-form :model="ruleInformation" :rules="rules" ref="ruleInformation" label-width="150px"
                 size="small" class="demo-ruleForm">
          <el-col :span="11">
            <el-form-item label="捐赠者/提供信息者" prop="donors">
              <el-input :disabled="inputDisable" v-model.trim="ruleInformation.donors"
                        placeholder="请输入捐赠者/提供信息者(限50字)"
                        maxlength="50" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="联系电话" prop="phone">
              <el-input :disabled="inputDisable" v-model.number.trim="ruleInformation.phone"
                        placeholder="请输入联系电话"
                        maxlength="11" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="邮箱" prop="email">
              <el-input :disabled="inputDisable" v-model.trim="ruleInformation.email" maxlength="30"
                        placeholder="请输入捐赠者/提供信息者的邮箱" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="地址" prop="address">
              <el-input type="textarea" rows="4" resize="none" :disabled="inputDisable"
                        v-model.trim="ruleInformation.address" maxlength="200"
                        placeholder="请输入捐赠者/提供信息者的地址(限200字)" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="藏品交接方式" prop="handoverMethod">
              <el-select :disabled="inputDisable" v-model.trim="ruleInformation.handoverMethod"
                         placeholder="请选择藏品交接方式" style="width: 100%"
                         clearable>
                <el-option
                    v-for="item in handoverList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="移交地点" prop="transferLocation">
              <el-input :disabled="inputDisable" v-model.trim="ruleInformation.transferLocation"
                        maxlength="100"
                        placeholder="请输入移交地点(限100字)" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="线索来源" prop="clueSources">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputDisable"
                        v-model.trim="ruleInformation.clueSources" maxlength="500"
                        placeholder="请输入线索来源(限500字)" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="捐赠/提供目的及意向" prop="intention">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputDisable"
                        v-model.trim="ruleInformation.intention" maxlength="500"
                        placeholder="请输入捐赠/提供目的及意向(限500字)例如：明确捐赠的目的和意向，如文物保护、学术研究、展览等，并说明是否希望获得相关证明或荣誉。"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="其他信息" prop="otherInfo">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputDisable"
                        v-model.trim="ruleInformation.otherInfo" maxlength="500"
                        placeholder="请输入内容(限500字)例如：根据实际情况，可能需要提供其它相关信息，如文物鉴定报告、相关机构或组织的推荐信等"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" rows="5" resize="none" :disabled="inputDisable"
                        v-model.trim="ruleInformation.remark" maxlength="500"
                        placeholder="请输入内容(限500字)" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="minTitle">藏品清单</div>
      <div class="flex_b_c">
        <div>藏品数量:{{ allDataList.length }}</div>
        <div v-if="butType != 1 && butType != 5">
          <el-button icon="el-icon-upload2" size="small" :disabled="allDataList.length >= 200" @click="importDile()">
            批量导入
          </el-button>
          <el-button icon="el-icon-download" size="small" @click="templateDownload()">导入模板下载</el-button>
          <el-button icon='el-icon-plus' :disabled="allDataList.length >= 200" size="small" type="primary"
                     @click="addData(5)">新增
          </el-button>
        </div>
        <div v-else>
          <el-button size="small" @click="exportData()">
            <i class="icon-piliangdaochu iconfont buIcon"/>
            批量导出
          </el-button>
        </div>
      </div>
      <el-table
          :data="dataList"
          size="small"
          v-loading="loading"
          ref="multipleTable"
          height="300px"
          class="table"
          :row-key="'id'"
          @selection-change="selectionChangeHandle"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="图标" width="120px">
          <template slot-scope="scope">
            <div class="flex_a_c">
              <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
              <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.picMasterViewUrl"
                  :fit="'cover'"
                  :preview-src-list="[scope.row.picMasterViewUrl]"
              >
                <div slot="error" class="image-slot">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="require('@/assets/img/default.png')"
                      :fit="'cover'">
                  </el-image>
                </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="collectionName" label="藏品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="collectionLevel" label="级别" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.collectionLevel | matchedData(leaveList) }}
          </template>
        </el-table-column>
        <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
        <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
        <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
          </template>
        </el-table-column>

        <el-table-column v-for="(item, index) in config" :key="item.archivesBasicId"
                         :prop="item.code"
                         :label="item.basicName" v-if="item.dataSelect == 0" min-width="120"
                         show-overflow-tooltip>
          <template slot="header" slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                        placement="top-start">
              <span class="omit">{{ item.forName ? item.forName : item.basicName }}</span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <span
                v-if="item.archivesBasicTypeCode == 'date'">{{ metadataDateFormatValue(item, scope.row.archivesData[item.code]) }}</span>
            <span v-else>{{ scope.row.archivesData[item.code] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建者" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.createDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="text" v-if="butType == 1 || butType == 5"
                       @click="addData(7, scope.$index)">详情
            </el-button>
            <el-button size="mini" type="text" @click="addData(6, scope.$index)"
                       v-if="butType == 0 || butType == 2">修改
            </el-button>
            <el-button size="mini" type="text" @click="deleteDate(scope.$index)"
                       v-if="butType == 0 || butType == 2">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="allDataList.length"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>

    <div v-if="butType == 0 || butType == 2" class="text_center" style="margin-top: 20px">
      <el-button size="small" @click=returnPage(1)>取消</el-button>
      <el-button type="primary" size="small" @click="submitTo(0)">保存</el-button>
      <el-button type="primary" size="small" @click="submitTo(1)">提交</el-button>
    </div>
    <div v-if="butType == 1 || butType == 5" class="text_center" style="margin-top: 20px">
      <el-button v-if="!see" size="small" type="primary" @click="switchData(1)">上一条</el-button>
      <el-button v-if="!see" size="small" type="primary" @click="switchData(0)">下一条</el-button>
      <el-button v-if="butType == 5 && see != 1" type="primary" size="small" @click="through(3)">通过</el-button>
      <el-button v-if="butType == 5 && see != 1" type="primary" size="small" @click="through(2)">驳回</el-button>
      <el-button size="small" @click=returnPage(1)>关闭</el-button>
    </div>

    <!--导入-->
    <el-dialog title="导入档案" :close-on-click-modal="false" append-to-body :visible.sync="visible">
      <div v-loading="importLoading" element-loading-text="数据导入中">
        <div v-if="!importLoading">
          <el-result icon="success" title="导入成功" :subTitle="'本次共导入'+importData.number+'条数据。'"
                     v-if="importData.code"></el-result>
          <el-result icon="error" title="导入失败" v-else></el-result>
          <div v-if="!importLoading && !importData.code" class="text_center">
            <div v-for="(item, index) in importData.list" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
            </span>
    </el-dialog>
    <temDownload ref="temDownload"></temDownload>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage(1)"></reviewSuggestions>
    <ExportData ref="exportData" @downLoad="getDataList('',1)"></ExportData>
    <ImportColl ref="importColl" @importFile="getImportData"></ImportColl>
  </div>
</template>

<script>
import validator from "@/utils/validator";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import ImportColl from "@/views/modules/collection/accounts/module/importColl.vue";
import temDownload from "@/views/modules/collection/accounts/module/temDownload.vue";
import ExportData from "@/views/modules/record/collect/exportData.vue";

export default {
  name: "addRegistration",
  components: {ExportData, temDownload, ImportColl, reviewSuggestions},
  computed: {
    validator() {
      return validator
    }
  },
  data() {
    return {
      butType: '0', //0新增 1详情 2修改 4重新提交 5审核详情
      see: false,
      inputDisable: false,
      ruleInformation: {
        id: '',
        donors: '',
        phone: '',
        email: '',
        address: '',
        handoverMethod: '',
        transferLocation: '',
        clueSources: '',
        intention: '',
        otherInfo: '',
        remark: '',
      },
      rules: {
        donors: [{required: true, message: '请输入捐赠者/提供信息者', trigger: 'blur'},],
        phone: [
          // {required: true, message: '请输入联系电话', trigger: 'blur'},
          {validator: validator.isMobile, message: '请输入正确的联系电话', trigger: 'blur'}
        ],
        email: [
          {validator: validator.isEmail, message: '请输入正确的邮箱', trigger: 'blur'}
        ],
        handoverMethod: [{required: true, message: '请选择藏品交接方式', trigger: 'blur'},],

      },
      handoverList: [
        {
          value: '邮寄',
          label: '邮寄',
        },
        {
          value: '到馆移交',
          label: '到馆移交',
        },
        {
          value: '约定移交地点',
          label: '约定移交地点',
        },
      ],

      dataList: [], //分页展示数据
      allDataList: [], //储存全部数据
      loading: false,
      dataListSelect: [], //选中数据
      config: [],
      pageNo: 1,
      pageSize: 10,
      pageSource: '',//页面来源，返回用

      importLoading: false,
      importData: {},
      visible: false,

      programExit: false,
      treeData: [],
      leaveList: [],
      registrationSearch: {},
      returnPageStatus:'',
    }
  },

  mounted() {
    this.butType = this.$route.query.butType
    this.see = this.$route.query.see
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.ruleInformation.id = this.$route.query.id
    }
    if (this.butType == 1 || this.butType == 5) {
      this.inputDisable = true
    }
    this.pageSource = this.$route.query.pageSource
    if (this.$route.query.haveData) {
      let pageAllData = JSON.parse(sessionStorage.getItem('pageAllData'))
      this.ruleInformation = pageAllData.ruleInformation
      this.allDataList = pageAllData.allDataList
      this.allDataList.forEach(item => {
        if (item.imgDataList && !item.picMasterViewUrl && item.imgDataList.length) {
          let daUrl = item.imgDataList.filter(item2 => {
            return item2.masterView == 0
          })
          this.$set(item, 'picMasterViewUrl', daUrl[0].netUrl)
        }
      })
      this.pageSource = pageAllData.pageSource
      this.butType = pageAllData.butType
      this.getDataList()
    } else {
      if (this.butType == 0) {
        this.$nextTick(() => {
          this.$refs.multipleTable.doLayout()
        })
      } else {
        this.getDetail()
      }
    }
    this.selRecord()
    this.getTreeData()
    this.getSelectData()
  },

  filters: {
    matchedData(value, data) {
      if (data && data.length != 0) {
        let setRow = data.filter(item => {
          return item.level == value
        })
        return setRow[0].levelName
      }
    }
  },

  methods: {
    getDetail() {
      this.$axios(this.api.collection.listInfo, {ids: [this.id]}, 'get').then((res) => {
        // this.$axios(this.api.collection.listCollection, {id:this.id}, 'get').then((res2) => {
        if (res.status) {
          this.ruleInformation = res.data
          this.allDataList = res.data.collectionDatas
          this.allDataList.forEach(item => {
            item.archivesData = item.archivesDataJSONObject
          })
          this.getDataList(1, 1)
        }
        // })
      })
    },

    //删除
    deleteDate(index) {
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.allDataList.splice((this.pageNo - 1) * this.pageSize + index, 1)
        this.getDataList('', 1)
      })
    },

    getTreeData() {
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.treeData = res.data
        }
      })
    },

    exportData() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return {
            id: item.id,
            archivesBasicDataId: item.archivesBasicDataId
          }
        }
      })
      this.$refs.exportData.init(ids, this.config, '', '藏品清单', 1)
    },

    getSelectData() {
      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
        }
      })
    },

    selRecord() {
      this.$axios(this.api.collection.getArchivesBasicByRequired).then(data => {
        if (data.status) {
          this.config = data.data.archivesBasicDataGroup
          this.config.map(item => {
            if (item.dataWhere == 0) {
              this.$set(item, 'value', '')
              this.$set(this.inputForm, item.code, '')
              if (item.archivesBasicTypeCode == 'integer') {
                this.$set(this.rules, item.code, [{
                  validator: this.validator.isDigits,
                  trigger: 'blur'
                }])
              }
            }
          })
          this.getDataList()
        }
      })
    },

    getImportData(list) {
      list.forEach(item => {
        item.archivesData = item.archivesDataJSONObject
      })
      this.allDataList.push(...list)
      this.getDataList(1, 1)
    },

    getDataList(type, dividePage) {
      if (type == 1) {
        this.pageNo = 1
      }
      if (dividePage == 1) {
        this.$refs.multipleTable.clearSelection()
      }
      if (this.pageNo * this.pageSize <= this.allDataList.length) {
        this.dataList = this.allDataList.slice((this.pageNo - 1) * this.pageSize, this.pageNo * this.pageSize)
      } else {
        this.dataList = this.allDataList.slice((this.pageNo - 1) * this.pageSize, this.allDataList.length)
      }
      setTimeout(() => {
        this.$refs.multipleTable.doLayout()
      }, 500)
    },

    //导入
    importDile(param) {
      this.$refs.importColl.init(this.treeData, this.api.collection.excelImportNoSave, this.allDataList.length)
    },

    //2驳回，3通过
    through(type) {
      let revdId
      // ,,,,url,methods,showData
      this.$refs.suggestions.init(1, [this.ruleInformation], type, revdId, this.api.collection.collectiondonateRevd)
    },

    //导入模板下载
    templateDownload() {
      // this.$refs.temDownload.init(this.treeData)
      this.$axios(this.api.collection.exportModel, {}, 'get', 'blob').then(res => {
        const blob = new Blob([res.data], {
          // 下载的文件格式自己在这边更改        type的值就好了
          type: 'application/vnd.ms-excel'
        })
        let filename = '藏品征集导入模板';
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        document.body.appendChild(link);
        link.click()
        URL.revokeObjectURL(link.href);  //释放url
        document.body.removeChild(link);  //释放标签
      })
    },

    //5新增 6修改 7详情
    addData(num, index) {
      let pageAllData = {
        pageSource: this.pageSource,
        butType: this.butType,
        setindex: (this.pageNo - 1) * this.pageSize + index,
        ruleInformation: this.ruleInformation,
        allDataList: this.allDataList,
      }
      this.programExit = true
      sessionStorage.setItem('pageAllData', JSON.stringify(pageAllData))
      this.$router.push({path: '/collection/accounts/addCollection', query: {butType: num}})
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      let frechUrl = this.api.collection.listNextOrPre
      if (this.pageType == 'toExamine') {
        frechUrl = this.api.collection.listRevNextOrPre
      }
      this.$axios(frechUrl, {
        nextInfo: num,
        ...this.registrationSearch
      }, 'get').then((res) => {
        if (res.status) {
          if (res.data) {
            this.id = res.data.id
            if (num == 0) {
              this.registrationSearch.viewCurrent++
            } else {
              this.registrationSearch.viewCurrent--
            }
            this.getDetail()
          } else {
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //取消
    returnPage(num) {
      this.returnPageStatus = num
      if (this.see) {
        this.$router.back()
      } else {
        if(this.pageSource = 'registration'){
          this.$router.push({path: `/collection/collect/${this.pageSource}`})
        }else {
          this.$router.push({path: `/collection/collect/${this.pageSource}`, query: {butType: this.butType}})
        }
      }
    },

    // 0保存 1提交
    submitTo(num) {
      this.$refs.ruleInformation.validate((valid) => {
        if (valid) {
          let fechUrl = this.api.collection.saveOrUpdateDraft
          if (num == 1) {
            fechUrl = this.api.collection.saveOrUpdateSubmit
          }
          this.$axios(fechUrl, {
            ...this.ruleInformation,
            collectionDatas: this.allDataList,
          }, 'post').then(data => {
            if (data.status) {
              this.$message.success(`${num == 0 ? '保存' : '提交'}成功！`)
              this.programExit = true
              this.returnPage()
            } else {
              this.$message.error(data.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList('', 2);
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
    } else if (this.butType == 0 || this.butType == 2) {
      this.$confirm('当前数据未保存，您确定退出吗？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //进去别的页面
        if (this.returnPageStatus){
          this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
        }
        next();
      }).catch(() => {
        next(false);
      });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
}
</style>